/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../hooks/auth';
import ReactPlayer from 'react-player';

export default function Video() {
  const { apiBackend, videoId } = useAuth();
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoTitle, setVideoTitle] = useState('');
  const playerRef = useRef(null);
  const [startTime, setStartTime] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);

  const handleSalvarTempoAtual = async (tempoAtual, tempoFinal) => {
    if (tempoAtual > 0 && tempoFinal > 0) {
      const percentualVisto = (tempoAtual / tempoFinal) * 100;
      const handleTempoAtualTempoFinal = tempoFinal - tempoAtual;
      const handleConcluido = handleTempoAtualTempoFinal < 10;

      try {
        const showVideoIdResponse = await apiBackend.showVideoId({
          aulaId: videoId,
        });

        const data = {
          id: showVideoIdResponse.message.videos[0].id,
          progresso: percentualVisto,
          concluido: handleConcluido,
        };

        await apiBackend.updateVideo({ data });
      } catch (error) {
        console.error('Erro ao salvar progresso do vídeo:', error);
      }
    }
  };

  useEffect(() => {
    const fetchVideoData = async () => {
      if (videoId) {
        try {
          const response = await apiBackend.showVideo({ aulaId: videoId });
          setVideoUrl(response.url);
          const showVideoIdResponse = await apiBackend.showVideoId({
            aulaId: videoId,
          });
          const percentualProgresso = parseFloat(
            showVideoIdResponse.message.videos[0].progresso
          );
          if (percentualProgresso === 1) {
            setStartTime(0);
          } else {
            setStartTime(percentualProgresso);
          }
          setHasStarted(false);
        } catch (error) {
          console.error('Erro ao buscar dados do vídeo:', error);
        }
      }
    };

    fetchVideoData();
  }, [videoId]);

  useEffect(() => {
    if (videoUrl && playerRef.current) {
      const intervalId = setInterval(() => {
        const tempoAtual = playerRef.current.getCurrentTime();
        const tempoFinal = playerRef.current.getDuration();
        handleSalvarTempoAtual(tempoAtual, tempoFinal);
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef.current && !hasStarted && videoUrl) {
      const player = playerRef.current;
      const duration = player.getDuration();
      const tempoAtual = (startTime / 100) * duration;
      player.seekTo(tempoAtual);
      setHasStarted(true);
    }
  }, [startTime, videoUrl]);

  const handleReady = () => {
    if (playerRef.current && !hasStarted) {
      const player = playerRef.current;
      const duration = player.getDuration();
      const tempoAtual = (startTime / 100) * duration;
      player.seekTo(tempoAtual);
      setHasStarted(true);
    }
  };

  useEffect(() => {
    const storedTitle = localStorage.getItem('currentVideoTitle');
    if (storedTitle) {
      setVideoTitle(storedTitle);
    } else {
      setVideoTitle('Titulo do video não encontrado');
    }
  }, [videoUrl]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      {videoUrl ? (
        <>
          <div
            style={{
              position: 'relative',
              paddingTop: '56.25%',
            }}
          >
            <ReactPlayer
              ref={playerRef}
              url={videoUrl}
              playing={hasStarted}
              controls
              width='100%'
              height='100%'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: '10px',
                overflow: 'hidden',
              }}
              onReady={handleReady}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          </div>
          <div style={{ paddingTop: 10, fontSize: 28, fontWeight: 'bold' }}>
            {videoTitle}
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: 20 }}>Nenhuma aula selecionada</span>
        </div>
      )}
    </div>
  );
}
