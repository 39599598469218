/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function deleteAula({ token, id }) {
  try {
    const data = {
      id,
    };

    const resVideo = await api.post('/admin/aula/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return resVideo.data;
  } catch (error) {
    throw { message: 'Não foi possível localizar os usuários', error: error };
  }
}
