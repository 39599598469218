import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { useAuth } from '../../hooks/auth';

const ItemPkg = styled.div`
  display: flex;
  width: 400px;
  height: 600px;
`;

const ContentPkg = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 64px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(11, 10, 13);
  border-image: initial;
  cursor: pointer;

  &:hover {
    transform: translateY(-7px);
    border-color: ${(props) => props.color || '#777'};
  }
`;

const ContentPkg2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0%;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: ${(props) => props.color || '#777'};
    font-weight: bold;
    font-size: 16px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.color || '#777'};
    border-image: initial;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 145px;
    margin: 105px 0px 55px;
  }

  & p {
    max-width: 270px;
    text-align: center;
    color: rgb(135, 134, 139);
    font-size: 16px;
    line-height: 26px;
  }

  & p strong {
    color: ${(props) => props.color || '#777'};
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Cor de fundo mais escura para o overlay */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #222; /* Cor de fundo escura para o conteúdo do modal */
  padding: 20px;
  border-radius: 8px;
  width: 500px; /* Define uma largura fixa para o modal */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ccc; /* Cor clara para o ícone de fechar */
  font-size: 24px;
  cursor: pointer;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #ddd; /* Cor clara para o texto */
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #333; /* Borda escura para combinar com o tema */
  background-color: #333; /* Fundo escuro para o input */
  color: #fff; /* Texto claro para os inputs */
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #5a5a5a; /* Cor de fundo para o botão */
  color: #fff; /* Texto claro para o botão */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Faz o botão expandir para a largura total */
`;

const ModalTitle = styled.h2`
  margin-bottom: 20px;
  color: #ddd; /* Cor clara para o título */
  font-size: 24px;
  text-align: center;
`;

function AddPackages({ setUpdateTemas, updateTemas }) {
  const { apiBackend } = useAuth();

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    color: '#F7DE20',
    textTitle: '',
    text: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorChange = (color) => {
    setFormData({
      ...formData,
      color: color.hex,
    });
  };

  const handleSubmit = async () => {
    try {
      await apiBackend.createTemas({ data: formData });
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateTemas(!updateTemas);
      setModalOpen(false);
    }
  };

  const closeModal = (e) => {
    if (e.target.id === 'modal-overlay') {
      setModalOpen(false);
    }
  };

  return (
    <div>
      <ItemPkg>
        <ContentPkg color='#fff' onClick={() => setModalOpen(true)}>
          <ContentPkg2 color='#fff'>
            <text style={{ fontSize: 100 }}>+</text>
            <div>
              <p
                style={{
                  color: '#ffffff',
                  fontSize: 24,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Adicionar Novos Temas
              </p>
            </div>
          </ContentPkg2>
        </ContentPkg>
      </ItemPkg>
      {modalOpen && (
        <ModalOverlay id='modal-overlay' onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={() => setModalOpen(false)}>
              &times;
            </CloseButton>
            <ModalTitle>Adicionar Novo Tema</ModalTitle>
            <InputGroup>
              <Label>Título:</Label>
              <Input
                type='text'
                name='title'
                value={formData.title}
                onChange={handleInputChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Cor:</Label>
              <SketchPicker
                color={formData.color}
                onChange={handleColorChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Título do Texto:</Label>
              <Input
                type='text'
                name='textTitle'
                value={formData.textTitle}
                onChange={handleInputChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Texto:</Label>
              <Input
                type='text'
                name='text'
                value={formData.text}
                onChange={handleInputChange}
              />
            </InputGroup>
            <Button onClick={handleSubmit}>Adicionar</Button>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
}

export default AddPackages;
