import React from 'react';

import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import { useAuth } from '../hooks/auth';

export default function Routes() {
  const { user } = useAuth();
  return user ? <MainRoutes /> : <LoginRoutes />;
}
