/* eslint-disable react/react-in-jsx-scope */
import React, { createContext, useContext, useState, useEffect } from 'react';

import { Api } from '../integration/backend/index';
import { api } from '../services/api';

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [data, setData] = useState({});
  const [videoId, setAulaId] = useState(undefined);

  async function signIn({ email, password }) {
    try {
      const response = await api.post('/login', { email, password });
      const { message } = response.data;

      const user = message.user;
      const token = message.token;

      localStorage.setItem('@videos:user', JSON.stringify(user));
      localStorage.setItem('@videos:token', token);

      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      setData({ user, token });

      console.log('Bem-vindo!');
      return;
    } catch (e) {
      if (e.response) {
        console.log(e.response.data.message);
      } else {
        console.log('Sem acesso ao sistema, tente novamente mais tarde!');
      }
      throw e;
    }
  }

  function singOut() {
    localStorage.removeItem('@videos:user');
    localStorage.removeItem('@videos:token');

    setData({});

    console.log('Deslogado com sucesso!');
  }

  useEffect(() => {
    const user = localStorage.getItem('@videos:user');
    const token = localStorage.getItem('@videos:token');

    if (user && token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      setData({ token, user: JSON.parse(user) });
    }
  }, []);

  const apiBackend = new Api(data.token);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        singOut,
        user: data.user,
        token: data.token,
        apiBackend,
        videoId,
        setAulaId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
