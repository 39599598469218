import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Lessons from '../pages/Lessons';
import Cursos from '../pages/Cursos';

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/dashboard' component={Home} />
        <Route path='/cursos' component={Cursos} />
        <Route path='/modulos' component={Lessons} />
      </Switch>
    </BrowserRouter>
  );
}
