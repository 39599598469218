/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function editUser({ token, id, values }) {
  const data = {
    id,
    name: values?.name,
    email: values?.email,
    status: values?.status,
    password: values?.password,
    userFunction: values?.userFunction,
    roles: values?.roles,
  };
  try {
    const res = await api.post('/admin/user/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível editar o usuários', error: error };
  }
}
