import React from 'react';
import styled from 'styled-components';
import PkgBG from '../../assets/pkg-background.png';
import { useHistory } from 'react-router-dom';

const ItemPkg = styled.div`
  display: flex;
  width: 400px;
  height: 600px;
`;

const ContentPkg = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: url(${PkgBG}) 0% 0% / cover;
  border-radius: 8px;
  transition: all 0.2s ease 0s;
  padding: 64px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(11, 10, 13);
  border-image: initial;

  &:hover {
    transform: translateY(-7px);
    border-color: ${(props) => props.color || '#777'};
  }
`;

const ContentPkg2 = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;

  & span {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: ${(props) => props.color || '#777'};
    font-weight: bold;
    font-size: 16px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.color || '#777'};
    border-image: initial;
  }

  & div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 145px;
    margin: 105px 0px 55px;
  }

  & p {
    max-width: 270px;
    text-align: center;
    color: rgb(135, 134, 139);
    font-size: 16px;
    line-height: 26px;
  }

  & p strong {
    color: ${(props) => props.color || '#777'};
  }
`;

function Packages({ color, title, textTitle, text, index, tipoCursoId }) {
  let history = useHistory();

  const handleSetIdTema = (id) => {
    localStorage.setItem('temaId', id.toString());
    history.push('/cursos');
  };

  return (
    <div onClick={() => handleSetIdTema(tipoCursoId)}>
      <ItemPkg>
        <ContentPkg color={color}>
          <ContentPkg2 color={color}>
            <span>{index}</span>
            <div>
              <text
                style={{
                  color: '#ffffff',
                  fontSize: 36,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {title}
              </text>
            </div>
            <p>
              <strong>{textTitle}</strong>
            </p>
            <text
              style={{
                color: '#ffffff',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {text}
            </text>
          </ContentPkg2>
        </ContentPkg>
      </ItemPkg>
    </div>
  );
}

export default Packages;
