/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function deleteUser({ token, id }) {
  const data = {
    id,
  };
  try {
    const res = await api.post('/admin/user/delete', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível deletar os usuários', error: error };
  }
}
