/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import './style.css';
import logoImg from '../../assets/logo.png';
import profileImg from '../../assets/profile.png';
import { useAuth } from '../../hooks/auth';

function Header({ page }) {
  let history = useHistory();
  const { user, singOut } = useAuth(); // Adicionando a função de sair

  // Função para fazer logout
  const handleSignOut = () => {
    singOut();
    history.push('/login'); // Redireciona para a página inicial após sair
  };

  return (
    <header className='header'>
      <div className='header-container'>
        <div className='header-box'>
          <Link
            to='/dashboard'
            style={{
              width: '156.69px',
              height: '35.09px',
            }}
          >
            <img src={logoImg} />
          </Link>

          {page !== 'home' ? (
            <div className='go-back' onClick={() => history.goBack()}>
              <FaArrowLeft style={{ marginRight: 13 }} size={14} /> Voltar
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='header-box'></div>
        <div className='header-box'>
          <div className='alert'>
            <button>
              <MdNotifications size={24} color='#FFFFFF' />
            </button>
          </div>

          <div className='profile'>
            <div>
              <span>{user?.name}</span>
              <span>{user?.email}</span>
            </div>
            <img src={profileImg} alt='Profile' />
            <button onClick={handleSignOut}>Sair</button> {/* Botão de sair */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
