/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa'; // Usando react-icons para o ícone de X
import Header from '../../components/Header';
import BgImage from '../../assets/background.png';
import CourseResume from '../../components/CourseResume';
import AddCourseResume from '../../components/AddCourseResume';
import { useAuth } from '../../hooks/auth';

// Estilização do componente
const Content = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const PackageContent = styled.div`
  display: flex;
  max-width: 1336px;
  padding-top: 71px;
  flex: 1 1 0%;
`;

const SideRight = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
`;

function CourseResumeExtended({
  name,
  modules,
  lessons,
  teacher,
  porcent,
  url,
  color,
  last,
  tipoCursoIdCerto,
  onDelete,
}) {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
    >
      <button
        style={{
          backgroundColor: 'red',
          border: 'none',
          color: 'white',
          padding: '10px 20px',
        }}
        onClick={() => onDelete(tipoCursoIdCerto)}
      >
        <FaTimes />
      </button>
      <CourseResume
        name={name}
        modules={modules}
        lessons={lessons}
        teacher={teacher}
        porcent={porcent}
        url={url}
        color={color}
        last={last}
        tipoCursoIdCerto={tipoCursoIdCerto}
      />
    </div>
  );
}

function Cursos() {
  const { apiBackend } = useAuth();
  const [cursos, setCursos] = useState([]);
  const [update, setUpdate] = useState(false);

  const tipoCursoId = localStorage.getItem('temaId');

  const handleShowCursos = async () => {
    try {
      const resCursos = await apiBackend.showCursos({ tipoCursoId });
      setCursos(resCursos.message.curso);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCurso = async (cursoId) => {
    try {
      await apiBackend.deleteCursos({ id: cursoId });
      setUpdate(!update); // Atualiza o estado para forçar recarregamento dos cursos
    } catch (error) {
      console.error('Falha ao deletar o curso: ', error);
    }
  };

  useEffect(() => {
    handleShowCursos();
  }, [update]);

  return (
    <div
      className='container'
      style={{
        background: `url(${BgImage}) 0% 0% / cover fixed, rgb(11, 10, 13)`,
      }}
    >
      <Header page='package' />
      <Content>
        <PackageContent>
          <SideRight>
            {cursos.map((curso, index) => (
              <CourseResumeExtended
                key={curso.id}
                name={curso.nome}
                modules='10'
                lessons='10'
                teacher={curso.teacher}
                porcent={`${curso.porcent}%`}
                url='/modulos'
                color={curso.color}
                last={index + 1 === cursos.length}
                tipoCursoIdCerto={curso.id}
                onDelete={handleDeleteCurso}
              />
            ))}
            <AddCourseResume
              key={1}
              name='Add curso'
              url='/modulos'
              color='#fff'
              update={update}
              setUpdate={setUpdate}
            />
          </SideRight>
        </PackageContent>
      </Content>
    </div>
  );
}

export default Cursos;
