import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ccc;
  font-size: 24px;
  cursor: pointer;
`;

export const Button = styled.button`
  padding: 8px 16px;
  background-color: #5a5a5a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #fff;
  margin-top: 10px;
`;

export const ProgressBar = styled.progress`
  width: 100%;
  margin-top: 10px;
`;

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  color: #ccc;
  font-size: 24px;
  cursor: pointer;
`;

export const ContextMenu = styled.div`
  position: absolute;
  right: 20px;
  background: white;
  border-radius: 4px;
  padding: 5px;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 2; // Make sure the menu is on top of other elements
`;
