/* eslint-disable no-useless-catch */
import showAula from './aula/showAula';
import showCursos from './curso/showCursos';
import showModulos from './modulo/showModulos';
import showTemas from './temas/showTemas';
import createTemas from './temas/createTemas';
import createUser from './usuarios/createUser';
import deleteUser from './usuarios/deleteUser';
import editUser from './usuarios/editUser';
import show from './usuarios/show';
import showUsuario from './usuarios/showUsuario';
import downloadAula from './video/downloadAula';
import showVideo from './video/showVideo';
import createCursos from './curso/createCursos';
import uploadVideo from './video/uploadVideo';
import createModulo from './modulo/createModulo';
import createAula from './aula/createAula';
import updateVideo from './video/updateVideo';
import deleteVideo from './video/deleteVideo';
import deleteAula from './aula/deleteAula';
import showVideoId from './video/showVideoId';
import deleteModulos from './modulo/deleteModulos';
import deleteCursos from './curso/deleteCursos';
import deleteTemas from './temas/deleteTemas';

class Api {
  token;

  constructor(token) {
    this.token = token;
  }

  // usuarios

  async showUsuarios() {
    try {
      return await show({ token: this.token });
    } catch (error) {
      throw error;
    }
  }

  async showUsuario({ id }) {
    try {
      return await showUsuario({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  async deleteUsuarios({ id }) {
    try {
      return await deleteUser({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  async createUser({ name, email, status, password }) {
    try {
      return await createUser({
        token: this.token,
        name,
        email,
        status,
        password,
      });
    } catch (error) {
      throw error;
    }
  }

  async editUser({ id, values }) {
    try {
      return await editUser({ token: this.token, id, values });
    } catch (error) {
      throw error;
    }
  }

  // temas
  async showTemas() {
    try {
      return await showTemas({ token: this.token });
    } catch (error) {
      throw error;
    }
  }

  async createTemas({ data }) {
    try {
      return await createTemas({ token: this.token, data });
    } catch (error) {
      throw error;
    }
  }

  async deleteTemas({ id }) {
    try {
      return await deleteTemas({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  // cursos

  async showCursos({ id, tipoCursoId }) {
    try {
      return await showCursos({ token: this.token, id, tipoCursoId });
    } catch (error) {
      throw error;
    }
  }

  async createCursos({ data }) {
    try {
      return await createCursos({ token: this.token, data });
    } catch (error) {
      throw error;
    }
  }

  async deleteCursos({ id }) {
    try {
      return await deleteCursos({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  // modulo

  async showModulos({ id, cursoId }) {
    try {
      return await showModulos({ token: this.token, id, cursoId });
    } catch (error) {
      throw error;
    }
  }

  async createModulo({ data }) {
    try {
      return await createModulo({ token: this.token, data });
    } catch (error) {
      throw error;
    }
  }

  async deleteModulos({ id }) {
    try {
      return await deleteModulos({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  // aula

  async showAula({ id, idModulo }) {
    try {
      return await showAula({ token: this.token, id, idModulo });
    } catch (error) {
      throw error;
    }
  }

  async createAula({ data }) {
    try {
      return await createAula({ token: this.token, data });
    } catch (error) {
      throw error;
    }
  }

  async deleteAula({ id }) {
    try {
      return await deleteAula({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }

  // video
  async downloadAula({ videoId2 }) {
    try {
      return await downloadAula({ token: this.token, videoId2 });
    } catch (error) {
      throw error;
    }
  }

  async showVideo({ aulaId }) {
    try {
      return await showVideo({ token: this.token, aulaId });
    } catch (error) {
      throw error;
    }
  }

  async showVideoId({ aulaId }) {
    try {
      return await showVideoId({ token: this.token, aulaId });
    } catch (error) {
      throw error;
    }
  }

  async uploadVideo({ video, onUploadProgress }) {
    try {
      return await uploadVideo({ token: this.token, video, onUploadProgress });
    } catch (error) {
      throw error;
    }
  }

  async updateVideo({ data }) {
    try {
      return await updateVideo({ token: this.token, data });
    } catch (error) {
      throw error;
    }
  }

  async deleteVideo({ id }) {
    try {
      return await deleteVideo({ token: this.token, id });
    } catch (error) {
      throw error;
    }
  }
}

export { Api };
