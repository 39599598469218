/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function showVideo({ token, aulaId }) {
  try {
    const data = {
      aulaId,
    };
    const resVideo = await api.post('/admin/video/show', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const res = await api.get(
      `/admin/video/download/${resVideo.data.message.videos[0].id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível localizar os usuários', error: error };
  }
}
