/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ResumeCircle from '../../ResumeCircle';
import './style.css';
import { useAuth } from '../../../hooks/auth';

import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  Button,
  Input,
  ProgressBar,
  MenuButton,
  ContextMenu,
} from './styles';

const Topics = ({ titulo, idModulo, handleShowModulos }) => {
  const { apiBackend, setAulaId } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [aulas, setAulas] = useState([]);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [videos, setVideos] = useState([]);
  const [showMenuIndex, setShowMenuIndex] = useState(-1);

  const calculateCompletedLessons = () => {
    return aulas.filter((aula) => aula.concluido).length;
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleShowAulas = async () => {
    console.log('faz o L');
    try {
      const resAulas = await apiBackend.showAula({ idModulo });
      setAulas(resAulas.message.aulas);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadVideo = async (video, title, index) => {
    if (!video) {
      console.error('Nenhum vídeo selecionado');
      return;
    }

    try {
      const resVideo = await apiBackend.uploadVideo({
        video: video,
        onUploadProgress: (progress) => {
          const newVideos = [...videos];
          newVideos[index].progress = progress;
          setVideos(newVideos);
        },
      });

      const videoId = resVideo.message.createVideo.id;

      const resAula = await apiBackend.createAula({
        data: {
          titulo: title,
          moduloId: idModulo,
        },
      });
      const aulaId = resAula.message.createAula.id;

      await apiBackend.updateVideo({
        data: {
          id: videoId,
          progresso: 1,
          concluido: false,
          aulaId: aulaId,
        },
      });

      console.log('Vídeo carregado e atualizado com sucesso');
      handleShowAulas();
    } catch (error) {
      console.error('Erro ao fazer upload do vídeo:', error);
    }
  };

  const handleDeleteAula = async (id, e) => {
    e.stopPropagation();
    try {
      const videoIdUp = await apiBackend.showVideoId({ aulaId: id });
      await apiBackend.deleteVideo({ id: videoIdUp.message.videos[0].id });
      await apiBackend.deleteAula({ id });
    } catch (error) {
      console.log(error);
    } finally {
      handleShowAulas();
    }
  };

  const handleDeleteModulo = async (id) => {
    try {
      await apiBackend.deleteModulos({ id });
      setModalOpen2(false);
    } catch (error) {
      console.log(error);
    } finally {
      handleShowModulos();
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newVideos = [...videos];
    for (let i = 0; i < files.length; i++) {
      newVideos.push({ file: files[i], title: files[i].name, progress: 0 });
    }
    setVideos(newVideos);
  };

  const handleTitleChange = (e, index) => {
    const newVideos = [...videos];
    newVideos[index].title = e.target.value;
    setVideos(newVideos);
  };

  const handleUploadAllVideos = async () => {
    for (let i = 0; i < videos.length; i++) {
      const { file, title } = videos[i];
      if (file && title) {
        await handleUploadVideo(file, title, i);
      }
    }
    setModalOpen2(false);
  };

  const toggleMenu = (index) => {
    setShowMenuIndex(index === showMenuIndex ? -1 : index);
  };

  const closeMenu = () => {
    setShowMenuIndex(-1);
  };

  const handleAulaClick = (aulaId, aulaTitulo) => {
    setAulaId(aulaId);
    localStorage.setItem('currentVideoTitle', aulaTitulo);
  };

  useEffect(() => {
    handleShowAulas();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleShowAulas();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className='module-content' onClick={toggleOpen}>
        <div className='resume'>
          <ResumeCircle
            sequence={aulas.length}
            total={aulas.length}
            done={calculateCompletedLessons()}
          />
        </div>
        <div className='desc'>
          <span>{titulo}</span>
          <span>{aulas.length} aulas</span>
        </div>
      </div>
      {isOpen && (
        <>
          {aulas.map((aula, index) => (
            <div
              className='module-details'
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
              key={index}
              onClick={() => handleAulaClick(aula.id, aula.titulo)}
            >
              <div
                style={{
                  display: 'flex',
                  width: 10,
                  height: 55,
                  backgroundColor: aula.concluido ? '#3CC52D' : '#FF0000',
                }}
              />
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 20,
                  color: 'white',
                  textAlign: 'start',
                  textDecoration: 'none',
                  fontSize: 15,
                  width: '100%',
                  height: 60,
                  backgroundColor: 'transparent',
                }}
              >
                Aula {index + 1}
              </button>

              <MenuButton
                style={{ padding: 10 }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMenu(index);
                }}
              >
                ...
              </MenuButton>
              {showMenuIndex === index && (
                <ContextMenu show={true}>
                  <Button onClick={(e) => handleDeleteAula(aula.id, e)}>
                    Excluir
                  </Button>
                  <Button onClick={closeMenu}>Cancelar</Button>
                </ContextMenu>
              )}
            </div>
          ))}
          <Button onClick={() => setModalOpen2(true)}>Adicionar Vídeo</Button>
          {modalOpen2 && (
            <ModalOverlay onClick={() => setModalOpen2(false)}>
              <ModalContent
                onClick={(e) => e.stopPropagation()}
                style={styles.modalContent}
              >
                <CloseButton
                  onClick={() => setModalOpen2(false)}
                  style={styles.closeButton}
                >
                  &times;
                </CloseButton>
                <h2>Adicionar Vídeos</h2>
                <input
                  type='file'
                  accept='video/*'
                  multiple
                  onChange={handleFileChange}
                  style={styles.inputFile}
                />
                <div style={styles.videosContainer}>
                  {videos.map((video, index) => (
                    <div key={index} style={styles.videoUploadSection}>
                      <Input
                        type='text'
                        placeholder='Título do vídeo'
                        value={video.title}
                        onChange={(e) => handleTitleChange(e, index)}
                        style={styles.input}
                      />
                      <ProgressBar
                        value={video.progress}
                        max='100'
                        style={styles.progressBar}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  onClick={handleUploadAllVideos}
                  style={styles.uploadButton}
                >
                  Upload Todos os Vídeos
                </Button>
                <Button
                  onClick={() => handleDeleteModulo(idModulo)}
                  style={styles.deleteButton}
                >
                  Excluir módulo
                </Button>
              </ModalContent>
            </ModalOverlay>
          )}
        </>
      )}
    </>
  );
};

const styles = {
  modalContent: {
    position: 'relative',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90%',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  videosContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  videoUploadSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  inputFile: {
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
  },
  progressBar: {
    width: '100%',
    marginBottom: '10px',
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '10px',
  },
  uploadButton: {
    backgroundColor: '#008CBA',
    color: 'white',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '10px',
  },
  deleteButton: {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    marginBottom: '10px',
  },
};

export default Topics;
