// import { api } from '../../../services/api';

// export default async function downloadAula({ token, videoId2 }) {
//   try {
//     const res = await api.get(`/admin/video/download/${videoId2}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       responseType: 'blob',
//     });

//     return res.data;
//   } catch (error) {
//     console.error('Erro ao baixar a aula:', error);
//     throw new Error('Não foi possível baixar a aula');
//   }
// }

import { api } from '../../../services/api';

export default async function downloadAula({ token, videoId2 }) {
  try {
    const res = await api.get(`/admin/video/download/${videoId2}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    });

    return res.data;
  } catch (error) {
    console.error('Erro ao baixar a aula:', error);
    throw new Error('Não foi possível baixar a aula');
  }
}
