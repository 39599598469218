import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import courseBG from '../../assets/course-background.png';
import bgImgCourse from '../../assets/bg-img-course.svg';
import { useAuth } from '../../hooks/auth';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 699px;
  height: 380px;
  position: relative;
  background: url(${courseBG}) center bottom, rgb(11, 10, 13);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 100px;
  transition: all 0.2s ease 0s;
  border-width: 2px 2px 2px;
  border-style: solid solid none;
  border-color: rgb(11, 10, 13);
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.color || '#777'};
  }

  &:hover::after,
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: ${(props) => props.porcent || '100%'};
    height: 3px;
    background: ${(props) => props.color || '#777'};
    transition: width 0.4s ease-in-out;
  }
`;

const Img = styled.div`
  width: 200px;
  height: 158px;
  margin-bottom: 30px;
  background: url(${bgImgCourse});
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  color: #fff;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #ccc;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #333;
  color: #fff;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #5a5a5a;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
`;

function AddCourseResume({ name, color, update, setUpdate }) {
  const { apiBackend } = useAuth();

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    tipoCursoId: localStorage.getItem('temaId'),
    porcent: 0.1,
    color: '#F7DE20',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === 'porcent' ? Number(value) : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleColorChange = (color) => {
    setFormData({ ...formData, color: color.hex });
  };

  const handleSubmit = async () => {
    try {
      await apiBackend.createCursos({ data: formData });
    } catch (error) {
      console.log(error);
    } finally {
      setModalOpen(false);
      setUpdate(!update);
    }
  };

  return (
    <>
      <Box color={color} onClick={() => setModalOpen(true)}>
        <Img />
        <h3>{name}</h3>
      </Box>
      {modalOpen && (
        <ModalOverlay onClick={() => setModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <InputGroup>
              <Label>Nome:</Label>
              <Input
                name='nome'
                value={formData.nome}
                onChange={handleInputChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Professor:</Label>
              <Input
                name='teacher'
                value={formData.teacher}
                onChange={handleInputChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Porcentagem:</Label>
              <Input
                type='number'
                name='porcent'
                value={formData.porcent}
                onChange={handleInputChange}
              />
            </InputGroup>
            <InputGroup>
              <Label>Cor:</Label>
              <SketchPicker
                color={formData.color}
                onChangeComplete={handleColorChange}
              />
            </InputGroup>
            <Button onClick={handleSubmit}>Salvar Dados</Button>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

export default AddCourseResume;
