import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';

export default function LoginRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login' component={Login} />
      </Switch>
    </BrowserRouter>
  );
}
