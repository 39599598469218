/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import BgImage from '../../assets/background.png';
import Packages from '../../components/Packages';
import { useAuth } from '../../hooks/auth';
import AddPackages from '../../components/AddPackages';
import { FaTimes } from 'react-icons/fa';

function Home() {
  const { apiBackend } = useAuth();

  const [temas, setTemas] = useState([]);
  const [updateTemas, setUpdateTemas] = useState(false);

  const handleShowTemas = async () => {
    try {
      const resTemas = await apiBackend.showTemas();
      setTemas(resTemas.message.temasResponse);
    } catch (error) {
      console.log('error ===>>>', error);
    }
  };

  const handleDeleteTema = async (id) => {
    console.log(id);
    try {
      await apiBackend.deleteTemas({ id });
    } catch (error) {
      console.log(error);
    } finally {
      handleShowTemas();
    }
  };

  useEffect(() => {
    handleShowTemas();
  }, [updateTemas]);

  return (
    <div
      className='container'
      style={{
        background: `url(${BgImage}) 0% 0% / cover fixed, rgb(11, 10, 13)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Header page='home' />
      <div
        style={{
          overflow: 'hidden',
          maxWidth: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: '30px 20px 10px 30px',
            gap: 20,
            overflowX: 'auto',
            maxWidth: '100%',
            marginBottom: '-30px',
            marginRight: '-20px',
          }}
        >
          {temas.map((tema, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            >
              <button
                style={{
                  backgroundColor: 'red',
                  border: 'none',
                  color: 'white',
                  padding: '10px 20px',
                }}
                onClick={() => handleDeleteTema(tema.tema.id)}
              >
                <FaTimes />
              </button>

              <Packages
                key={index}
                index={index + 1}
                color={tema.tema.color}
                title={tema.tema.title}
                textTitle={tema.tema.textTitle}
                text={tema.tema.text}
                tipoCursoId={tema.tema.id}
              />
            </div>
          ))}
          <AddPackages
            color='#000'
            updateTemas={updateTemas}
            setUpdateTemas={setUpdateTemas}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
