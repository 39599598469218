/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function createUser({
  token,
  name,
  email,
  status,
  password,
}) {
  const data = {
    name,
    email,
    status,
    password,
  };
  try {
    const res = await api.post('/admin/user/create', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw {
      message: 'Não foi possível criar o usuários',
      error: error,
    };
  }
}
