// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect } from 'react';
// import Header from '../../components/Header';
// import BgImage from '../../assets/background.png';
// import Video from '../../components/Video';
// import Modules from '../../components/Modules';
// import { useAuth } from '../../hooks/auth';

// function Lessons() {
//   const { setAulaId } = useAuth();

//   useEffect(() => {
//     setAulaId(undefined);

//     return () => {
//       setAulaId(null);
//     };
//   }, [setAulaId]);

//   return (
//     <div
//       className='container'
//       style={{
//         background: `url(${BgImage}) 0% 0% / cover fixed, rgb(11, 10, 13)`,
//       }}
//     >
//       <Header page='lessons' />
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: ' center',
//           paddingTop: 30,
//         }}
//       >
//         {window.innerWidth > 768 ? (
//           <div
//             style={{
//               display: 'flex',
//               width: 1640,
//             }}
//           >
//             <Video />
//             <Modules />
//           </div>
//         ) : (
//           <div
//             style={{
//               display: 'flex',
//               width: 1640,
//               flexDirection: 'column',
//             }}
//           >
//             <Video />
//             <Modules />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Lessons;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import BgImage from '../../assets/background.png';
import Video from '../../components/Video';
import Modules from '../../components/Modules';
import { useAuth } from '../../hooks/auth';

function Lessons() {
  const { setAulaId } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    setAulaId(undefined);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      setAulaId(null);
      window.removeEventListener('resize', handleResize);
    };
  }, [setAulaId]);

  return (
    <div
      className='container'
      style={{
        background: `url(${BgImage}) 0% 0% / cover fixed, rgb(11, 10, 13)`,
      }}
    >
      <Header page='lessons' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            width: 1640,
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Video />
          <Modules isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
}

export default Lessons;
