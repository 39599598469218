/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function uploadVideo({ token, video, onUploadProgress }) {
  try {
    const formData = new FormData();
    formData.append('video', video);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        onUploadProgress(percentCompleted);
      },
    };

    const response = await api.post('/admin/video/upload', formData, config);
    return response.data;
  } catch (error) {
    throw new Error('Não foi possível fazer o upload do vídeo', {
      cause: error,
    });
  }
}
