/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function showTemas({ token }) {
  try {
    const res = await api.post('/admin/tema/show', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível localizar tema', error: error };
  }
}
