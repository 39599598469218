/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function showVideoId({ token, aulaId }) {
  try {
    const data = {
      aulaId,
    };
    const resVideo = await api.post('/admin/video/show', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return resVideo.data;
  } catch (error) {
    throw { message: 'Não foi possível localizar os usuários', error: error };
  }
}
