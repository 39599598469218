/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import BgImage from '../../assets/background.png';
import { useAuth } from '../../hooks/auth';

function Login() {
  const { signIn } = useAuth();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      await signIn({ email: username, password: password });
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className='container'
      style={{
        background: `url(${BgImage}) 0% 0% / cover fixed, rgb(11, 10, 13)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 20,
          padding: 20,
          borderRadius: 10,
          backgroundColor: '#FFFFFF0A',
          textAlign: 'center',
        }}
      >
        <h2 style={{ marginBottom: 20 }}>Faça Login</h2>
        <input
          type='text'
          placeholder='Nome de Usuário'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            border: '1px solid #ccc',
            width: 300,
          }}
        />
        <input
          type='password'
          placeholder='Senha'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            marginBottom: 10,
            padding: 10,
            borderRadius: 5,
            border: '1px solid #ccc',
            width: 300,
          }}
        />
        <button
          onClick={handleLogin}
          style={{
            padding: 10,
            borderRadius: 5,
            border: 'none',
            backgroundColor: '#4CAF50',
            color: 'white',
            width: 150,
          }}
        >
          Entrar
        </button>
      </div>
    </div>
  );
}

export default Login;
