/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function updateVideo({ token, data }) {
  try {
    const res = await api.post('/admin/video/update', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível editar o usuários', error: error };
  }
}
