/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Topics from './Topics';
import { useAuth } from '../../hooks/auth';

import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  Button,
  ModalTitle,
  InputGroup,
  Label,
  Input,
} from './styles';

const Modules = ({ isMobile }) => {
  const { apiBackend } = useAuth();
  const nameCurso = localStorage.getItem('nameCurso');
  const cursoId = localStorage.getItem('cursoId');

  const [modalOpen, setModalOpen] = useState(false);
  const [modulos, setModulos] = useState([]);
  const [dataCreateModulo, setDataCreateModulo] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCreateModulo = async () => {
    setLoading(true);
    try {
      const resModulo = await apiBackend.createModulo({
        data: dataCreateModulo,
      });
      const moduloId = resModulo.message.createModulo.id;
      console.log('Módulo criado, ID:', moduloId);
    } catch (error) {
      console.error('Erro no processo de criação do módulo:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
      setModalOpen(false);
      handleShowModulos();
    }
  };

  const handleChangeModulo = (e) => {
    setDataCreateModulo({ titulo: e.target.value, cursoId });
  };

  const handleShowModulos = async () => {
    try {
      const resModulos = await apiBackend.showModulos({ cursoId });
      setModulos(resModulos.message.modulos);
    } catch (error) {
      console.error('Erro ao carregar módulos:', error);
    }
  };

  useEffect(() => {
    handleShowModulos();
  }, []);

  const closeModal = (e) => {
    if (e.target.id === 'modal-overlay') {
      setModalOpen(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: isMobile ? '100%' : '400px',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'initial',
          background: 'rgb(25, 24, 31)',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '700px',
            overflowY: 'scroll',
            marginRight: '-15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '30px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
            }}
          >
            <span style={{ color: '#868686', fontSize: '15px' }}>
              {nameCurso}
            </span>
          </div>

          {modulos.map((modulo, index) => (
            <Topics
              key={index}
              titulo={modulo.titulo}
              idModulo={modulo.id}
              handleShowModulos={handleShowModulos}
            />
          ))}
          <div
            style={{
              border: '1px solid #868686',
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              marginTop: '20px',
              padding: '10px',
            }}
            onClick={() => setModalOpen(true)}
          >
            <div>
              <span style={{ color: '#868686' }}>+ Adicionar módulos</span>
            </div>
          </div>
          {modalOpen && (
            <ModalOverlay id='modal-overlay' onClick={closeModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={() => setModalOpen(false)}>
                  &times;
                </CloseButton>
                <ModalTitle>Criar módulo</ModalTitle>
                <InputGroup>
                  <Label>Título:</Label>
                  <Input name='nome' onChange={handleChangeModulo} />
                </InputGroup>
                <Button onClick={handleCreateModulo}>Adicionar</Button>
              </ModalContent>
            </ModalOverlay>
          )}
          {loading && <div>Carregando...</div>}
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Modules;
