/* eslint-disable no-throw-literal */
import { api } from '../../../services/api';

export default async function show({ token, id }) {
  try {
    if (id) {
      const data = {
        id,
      };
      const res = await api.post('/admin/user/show', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    }
    const res = await api.post('/admin/user/show', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (error) {
    throw { message: 'Não foi possível localizar os usuários', error: error };
  }
}
